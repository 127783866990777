$support-white: #EFF1F9
$support-gray: rgba(177, 177, 177, 1)
$support-black: #181D28

.support
  position: fixed
  right: 34px
  bottom: 32px
  z-index: 99

  &__panel
    background: $white
    border-radius: 2px !important
    box-shadow: 1px 1px 3px 0 rgba(50, 50, 120, 0.2)
    color: $main-dark-blue

    .mat-menu-content:not(:empty)
      padding-top: 0
      padding-bottom: 0
      margin-bottom: 0
      height: 100%

    button
      cursor: pointer

  &__trigger
    cursor: pointer
    padding: 16px 13px
    display: flex
    span
      margin-left: 10px
      font-weight: 800

  &__btn
    margin: auto
    width: 180px

    &:hover,
    &:focus
      img
        filter: grayscale(1) brightness(3)

    img
      margin-right: 10px

  &__dialogsMenu
    bottom: -54px
    width: 338px
    max-width: 338px !important
    height: 470px
    z-index: 999
    position: relative
    overflow: visible !important

    .mat-mdc-menu-content
      padding: 0
      height: 100%

  &__menuInner
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

  &__dialogsList
    max-height: 310px
    height: 310px

  &__dialog
    padding: 13px 25px 11px 30px
    display: flex
    align-items: flex-start
    background: transparent
    transition: background .1s ease
    cursor: pointer

    &:hover,
    &--active
      background: rgba(234, 234, 240, .5)

    & > img
      margin-right: 15px
      margin-top: 3px

    &Text
      margin-right: 15px
      color: $black

    &Date
      font-size: 15px
      font-style: italic
      color: $support-gray

  &__unread
    // background: rgba(234, 234, 240, 1)
    background: $button
    // color: $support-black
    color: $white
    padding: 2px 8px
    font-size: 15px
    font-weight: 800
    border-radius: 3px
    text-align: center
    margin-left: auto

    &--floating
      position: absolute
      top: -5px
      right: -5px

  &__title
    border-bottom: 1px solid $transparent-grey
    padding: 30px
    display: flex
    justify-content: center
    align-items: center
    font-size: 20px
    font-weight: 800
    pointer-events: none

  &__close
    border-top: 1px solid $transparent-grey
    padding: 24px
    display: flex
    justify-content: center
    align-items: center
    font-size: 18px
    font-weight: 800
    cursor: pointer

    span
      margin: 0 10px 0 13px

  &__chat
    &Trigger
      position: absolute

    &Menu
      width: 586px
      max-width: 100vw !important
      height: 470px
      position: relative
      overflow: visible !important

      .mat-mdc-menu-content
        padding: 0
        height: 100%

    &Close
      padding: 4px
      margin-left: 20px

      &--mobile
        margin-left: 0
        margin-right: 8px
        padding: 4px 0
        margin-bottom: -2px

  &__messageList
    height: 339px
    max-height: 339px
    padding: 0 20px
    display: flex
    flex-direction: column

  &__start
    margin: auto
    text-align: center
    font-size: 20px
    font-weight: 800

  &__input
    padding: 10px 15px 15px
    display: flex

    .fields
      flex: 1
      margin: 0 20px 0 15px

    button
      border: none
      cursor: pointer
      width: 20px
      height: 20px
      align-self: center
      background-color: transparent
      color: $button
      opacity: .3
      margin-left: 12px
      +transition(.2s)

      &:hover
        opacity: 1

      mat-icon
        color: $button
        width: 20px
        height: 20px
        font-size: 20px

    .mat-form-field
      border: 1px solid $blueberry-20
      background-color: $white
      border-radius: 4px
      box-sizing: content-box
      +transition(.2s)

      .mat-form-field-flex
        align-items: center
        padding: 0 10px 0 15px

      .input
        font-size: 13px
        line-height: 20px
        font-family: 'Open Sans', sans-serif
        color: $main-dark-blue
        overflow: hidden
        //width: calc(100% - 80px)
        //emoji button only
        width: calc(100% - 30px)

      .mat-form-field-infix
        padding: 12px 0
        border: none

      .mat-form-field-label-wrapper
        top: 0
        padding-top: 0

      .mat-form-field-label
        top: 12px
        font-size: 13px
        line-height: 18px
        font-family: 'Open Sans', sans-serif
        letter-spacing: -.2px
        color: $gray

      .mat-form-field-underline
        display: none

      .mat-form-field-wrapper
        padding-bottom: 0

      .mat-form-field-suffix
        display: flex
        width: 20px
        height: 20px
        overflow: hidden
        cursor: pointer

      &.mat-focused
        border-color: $main-light-blue

        .mat-form-field-suffix
          opacity: .6

        .mat-form-field-label
          color: $main-light-blue

      &.mat-form-field-invalid
        border-color: $red

        .mat-form-field-label
          color: $red

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    padding: 25px 15px 10px 25px

    span
      margin-right: auto
      font-size: 20px
      font-weight: 800

      &:before
        content: ''
        width: 8px
        height: 8px
        background: $button
        border-radius: 8px
        margin-right: 10px
        display: inline-block
        margin-bottom: 3px

    button
      background: transparent
      border: none
      display: flex
      align-items: center
      color: $button

  &__assistance
    font-weight: 800

    img
      margin-right: 12px
      height: 12px

  &__rate
    height: 409px

  &__mail
    filter: brightness(5)

  &--scrollable
    overflow: auto

    &::-webkit-scrollbar
      width: 4px

    &::-webkit-scrollbar-track
      background: transparent

    &::-webkit-scrollbar-thumb
      background-color: $info-bg
      border-radius: 4px

  @media screen and (max-width: 1000px)
    right: 16px
    bottom: 36px

    &__trigger
      padding: 17px 8px 17px 13px
      background: $main-dark-blue
      border-radius: 3px !important

      span
        color: $white

      img
        filter: invert(1) brightness(2)

    &__dialogsMenu
      bottom: -57px

    &__header
      padding-top: 20px
      padding-bottom: 20px
      border-bottom: 1px solid #D9D9D9

      span
        font-size: 14px

        &::before
          margin-bottom: 1px

    &__assistance
      width: 90px
      font-size: 12px
      line-height: normal

    &__messageList
      height: 326px
      max-height: 326px

    &__start
      font-size: 16px

    &__rate
      height: 393px

    &__unread--floating
      font-size: 12px
      top: -8px
      right: -8px
      padding: 0px 6px

.message
  display: flex
  margin-bottom: 15px
  gap: 15px
  padding-right: 59px

  &__avatar
    width: 44px
    min-width: 44px
    height: 44px
    border-radius: 44px
    overflow: hidden
    margin-top: 23px
    background: $info-bg url(/assets/svg/icons/support/help.svg) center center no-repeat

  &__sender,
  &__date
    color: $support-gray
    font-size: 13px
    text-align: left

  &__sender
    margin-bottom: 4px

  &__text
    background: $info-bg
    padding: 12px
    border-radius: 3px
    margin-bottom: 8px
    color: $support-black

  &--sent
    flex-direction: row-reverse
    padding-left: 59px
    padding-right: 0

    .message__sender
      text-align: right

    .message__avatar
      background-image: url(/assets/svg/icons/support/user.svg)

  @media screen and (max-width: 1000px)
    padding-right: 40px

    &__avatar
      height: 25px
      width: 25px
      min-width: 25px
      background-size: 13px
      margin-top: 23px

    &__sender,
    &__date
      font-size: 10px

    &__text
      font-size: 14px
      padding: 10px

    &--sent
      padding-left: 40px
      padding-right: 0

.rate
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%

  h4
    text-align: center
    font-size: 40px
    font-weight: 300
    line-height: normal
    margin-bottom: 30px

  &__stars
    display: flex
    flex-direction: row-reverse
    justify-content: space-between
    margin-bottom: 60px

  &__star
    cursor: pointer
    padding-right: 5px

    &:first-child
      padding-right: 0

    svg
      transition: all .1s ease

  .rate__star:hover svg,
  .rate__star--active svg,
  .rate__star:hover ~ .rate__star svg,
  .rate__star--active ~ .rate__star svg
    fill: $button

  button
    margin: 0 auto
    width: 180px

  @media screen and (max-width: 1000px)
    h4
      font-size: 24px

    &__star svg
      width: 40px