@use '@angular/material' as mat

@import 'assets/global'

@include mat.core()

@import 'assets/support'

*
  margin: 0
  padding: 0
  box-sizing: border-box
  &:focus
    outline: 0
html
  scroll-behavior: smooth
body, html
  height: 100%
body
  font-family: 'Open Sans', sans-serif
  background: $body-color
  position: relative
  padding-bottom: 50px
  height: auto
  min-height: 100%
  mat-icon.mat-icon
    width: auto
    height: auto
  .mat-radio-button, .mat-checkbox
    font-family: 'Open Sans', sans-serif

//mobile app block

.mobile-block
  max-width: 100%
  background-color: $white
  padding: 20px 30px 40px
  text-align: center
  margin: 30px
  box-shadow: 0 0 16px 0 rgba(50, 50, 120, 0.3)
  border-radius: 8px
  color: $main-dark-blue

  &.show
    display: block

  h1
    font-size: 24px
    line-height: 30px
    letter-spacing: -.25px
    margin-bottom: 20px
    font-weight: 600

  .subtitle
    font-size: 20px
    line-height: 36px
    letter-spacing: -.25px
    font-weight: 600
    margin-bottom: 20px

  img
    display: block
    margin: auto
    margin-bottom: 20px

  .description
    margin-bottom: 30px
    p
      font-size: 13px
      letter-spacing: -.2px
      text-align: left
      font-weight: 600
      margin-bottom: 19px

    .title
      font-size: 16px
      text-transform: uppercase
      line-height: 24px
      margin-bottom: 20px

  .soon
    font-size: 30px
    letter-spacing: -.38px
    font-weight: 600

@media screen and (max-width: 600px)
  .mobile-block
    padding: 20px 20px 30px

    .description
      margin-bottom: 20px

@media screen and (max-width: 420px)
  body
    //background-color: $white

  .mobile-block
    box-shadow: none
    margin: 0
    border-radius: 0
    margin-bottom: 20px

    h1
      font-size: 24px
      line-height: 30px
      letter-spacing: -.38px
