=transition($duration)
  transition: $duration all

=main-text($color)
  font-size: 13px
  color: $color
  letter-spacing: -.2px

//media queries
@mixin tablet
  @media (max-width: $bp-tablet)
    @content

@mixin mobile-lg
  @media (max-width: $bp-mobile-lg)
    @content

@mixin mobile
  @media (max-width: $bp-mobile)
    @content
