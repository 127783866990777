$body-color: #f2f2f2

$main-dark-blue: #2d2355
$main-light-blue: #8c64e6
$main-lighter-blue: rgba(138, 101, 228, 0.5)
$transparent-light-blue: #7a7492

$gray: #b1b1b1
$dark-gray: #3d424f
$light-gray: #a0a0a0
$transparent-grey: #d6d6e4

$white: #fff
$black: #25244a
$blackened: #010101

$button: #8a65e4

$red: #ff2d55
$orange: #f5a623
$green: #6dcd05

$blueberry: #323278
$blueberry-65: rgba(50, 50, 120, 0.65)
$blueberry-30: rgba(50, 50, 120, 0.3)
$blueberry-20: rgba(50, 50, 120, 0.2)
$blueberry-10: rgba(50, 50, 120, 0.1)

$info-bg: #eaeaf1
$icon-transparent: #c0bdcc

$mat-item-hover: rgba(45, 35, 85, 0.1)
$mat-slider-track-bg: #e0e0e0

//spinner colors
$s0: #370C99
$s1: #5A2AC4
$s2: #3D1596
$s3: #5A2AC4
$s4: #7745E8
$s5: rgba(138,101,228,.5)
$s6: rgba(138,101,228,.2)

//breakpoints
$bp-tablet: 768px
$bp-mobile-lg: 480px
$bp-mobile: 375px
