@import '_variables'
@import '_mixins'
@import 'animation'
app-registration, app-login-registration
  z-index: 11
::ng-deep .mat-radio-button .mat-radio-ripple,
::ng-deep .mat-checkbox .mat-checkbox-ripple
  display: none
//button
::ng-deep .mat-form-field-infix, ::ng-deep .mat-form-field
  width: 100% !important
.button
  width: 100%
  height: 40px
  display: flex
  cursor: pointer
  border-radius: 2px
  font-size: 14px
  background-color: transparent
  border: none
  align-items: center
  justify-content: center
  font-family: 'Open Sans', sans-serif
  position: relative
  font-weight: 600
  +transition(.2s)
  &.stroke
    color: $button
    border: 1px solid $button
    &:hover
      color: $white
      background: $button
  &.fill
    color: $white
    background: $button
    &:hover
      color: $button
      border: 1px solid $button
      background-color: transparent
  &.disabled
    pointer-events: none
    border-color: rgba(50, 50, 120, 0.2)
    color: rgba(52, 51, 119, 0.25)
//input

.form-field
  position: relative
  .icon-error
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
    color: rgba($main-dark-blue, 0.2)
    cursor: pointer
    user-select: none
    background-color: transparent
    border: none
    &:hover
      & + .error-container
        opacity: 1
        pointer-events: auto
  .error-container
    opacity: 0
    pointer-events: none
    position: absolute
    z-index: 2
    right: -5px
    top: -15px
    +transition(.2s)
  &.mat-form-field-invalid
    .icon
      //display: none
      &-error
        display: block
        right: 0
        &.error-shifted
          right: 28px
    .input
      border-bottom-color: $red
    mat-icon
      font-size: 22px
      color: $red
  .input
    width: 100%
    height: 36px
    font-size: 13px
    font-family: 'Open Sans', sans-serif
    color: $main-dark-blue
    border: none
    +transition(.2s)
    &:focus
      border-bottom-color: $main-dark-blue
  .icon
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
    color: rgba($main-dark-blue, 0.2)
    cursor: pointer
    user-select: none
    background-color: transparent
    border: none
    &-error
      z-index: 2

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent
  background-color: $red !important

//soc
.soc-icon
  cursor: pointer
  background-color: transparent
  padding: 0
  position: relative
  width: 40px
  height: 40px
  overflow: hidden
  border: none
  display: flex
  align-items: center
  +transition(.2s)
  svg
    position: relative
    z-index: 2
    background: $white
    max-height: 100%
    +transition(.2s)
  img
    position: absolute
    z-index: 1
    top: 0
    left: 0
  &:hover
    svg
      opacity: 0
  &.round
    width: 56px
    height: 56px
    img
      width: 100%
      height: 100%
      &:first-child
        position: relative
        z-index: 2
        background: $white
        +transition(.2s)
    &:hover
      img:first-child
        opacity: 0
  &.disabled
    opacity: .3

.loader-wrap
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 20
  background: rgba($white, .65)
  .loader
    height: 96px
    width: 96px
    animation: spin .8s infinite steps(8)
    position: absolute
    top: 50%
    margin-top: -48px
    left: 50%
    margin-left: -48px
    &:before, &:after,
    .loader-el:before, .loader-el:after
      content: ''
      position: absolute
      top: 0
      left: 44px
      width: 7px
      height: 32px
      border-radius: 4px
      background: $s0
      box-shadow: 0 64px $s6
      transform-origin: 55% 48px
    &:before
      background: $s0
      box-shadow: 0 64px $s5
    &:after
      transform: rotate(-45deg)
      background: $s2
    .loader-el:before
      transform: rotate(-90deg)
      background: $s3
    .loader-el:after
      transform: rotate(-135deg)
      background: $s4

::ng-deep .mat-dialog-container
  padding: 0 !important
  box-shadow: none
  background: none
  overflow: unset

.modal
  box-shadow: 0 0 16px 0 rgba(50, 50, 120, 0.6)
  background-color: $white
  border-radius: 8px
  text-align: center

//snackbar

snack-bar-container.snack-notification
  margin: 10px
  padding: 15px 20px
  background: rgba(1, 1, 1, 0.54)
  box-shadow: none
  border-radius: 6px
  max-width: 300px
  min-width: 0

  .mat-simple-snackbar
    font: 13px 'Open Sans', sans-serif
    color: $white
    font-weight: normal
    line-height: 16px

//ng5 slider

::ng-deep
  .ng5-slider
    margin: 28px 0 10px 0

    .ng5-slider-bar
      background-color: $mat-slider-track-bg
      height: 4px
      border-radius: 3px

    .ng5-slider-pointer
      width: 12px
      height: 12px
      top: -4px
      background-color: $button
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3)

      &:after
        display: none

    .ng5-slider-selection
      background-color: $main-lighter-blue

    .ng5-slider-bubble
      font: 13px 'Open Sans', sans-serif
      line-height: 18px
      color: $main-dark-blue
      letter-spacing: -.2px

      &.ng5-slider-ceil
        left: calc(100% - 20px) !important

//link
.link
  color: $button
  font-weight: 600
  text-decoration: none
//end ng5 slider

// text

.text-main
  +main-text($main-dark-blue)

.text-semibold
  +main-text($main-dark-blue)
  font-weight: 600

//end text

//avatar

.avatar
  border-radius: 50%
  position: relative
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  object-fit: cover
  color: $main-dark-blue
  background: $info-bg url('/src/assets/svg/icons/avatar.svg') center no-repeat
  background-size: 80%
  flex-shrink: 0

  &.smallest
    width: 28px
    height: 28px
    background-size: 20px

  &.small
    width: 32px
    height: 32px
    background-size: 24px

  &.middle
    width: 36px
    height: 36px
    background-size: 30px

  &.large
    width: 48px
    height: 48px
    background-size: 32px

  img
    height: 100%
    width: 100%
    object-fit: cover

//warning

.warning
  background-color: $blueberry-10
  border-radius: 2px
  display: flex
  padding: 10px
  margin-bottom: 20px

  img
    flex-grow: 1
    height: 36px
    min-width: 36px
    margin-right: 15px

  p
    color: $main-dark-blue
    letter-spacing: -.2px
    font-size: 13px

//hint

.hint
  visibility: hidden
  opacity: 0
  position: absolute
  top: calc(100% + 10px)
  right: 0
  background-color: $white
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3)
  border-radius: 4px
  min-width: 180px
  padding: 15px 20px
  z-index: 1
  transition: visibility 0s .2s, opacity ease-in-out .2s

  p, a
    font-size: 13px
    font-weight: 600
    letter-spacing: -.2px
    line-height: 18px
    margin: 0 !important

  .title
    color: $black
    margin-bottom: 10px

  &.arrow
    box-shadow: none
    filter: drop-shadow(0px 0px 4px rgba(50, 50, 120, 0.5))

    &:after
      content: ''
      position: absolute
      width: 0
      height: 0

  &.above
    top: unset
    bottom: calc(100% + 10px)
    left: -30px

    &.arrow:after
      border-top: 10px solid $white
      border-left: 10px solid transparent
      border-right: 10px solid transparent
      bottom: -10px
      left: 60px

  &.right
    left: calc(100% + 25px)
    top: -8px
    padding-left: 10px

    &.arrow:after
      border-right: 10px solid $white
      border-top: 10px solid transparent
      border-bottom: 10px solid transparent
      left: -10px
      top: 20px

.hint-trigger
  position: relative
  cursor: pointer

  &:hover
    .hint
      visibility: visible
      opacity: 1
      transition: opacity ease-in-out .2s

//material select styles

::ng-deep .custom-select
  .mat-option
    &:hover
      background-color: $info-bg

    &.mat-active,
    &.mat-selected
      background-color: $info-bg !important
      color: $main-dark-blue !important

    &.mat-selected
      color: $black
      font-weight: 600

    .mat-option-text
      font: 13px 'Open Sans', sans-serif
      color: $main-dark-blue

// sticky elements
.sticky-block
  position: sticky !important
  top: 0
  z-index: 12


//adaptive
app-registration, app-login-registration
  @include tablet
    z-index: 12

.modal
  @include mobile-lg
    padding: 40px 20px !important

.form-field
  @include tablet
    ::ng-deep .mat-error
      .error-container
        opacity: 1
        right: unset
        top: unset

        span
          padding: 0
          background: transparent
          border: none
          top: 20px
          font-size: 10px !important

h1
  @include mobile-lg
    border-left: 2px solid $main-light-blue
    border-bottom: 1px solid $blueberry-10
    font-size: 24px !important
    letter-spacing: -.3px !important
    padding: 10px 0 12px 18px
